* {
  /* border: 1px solid red; */
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.footer-link {
  transition-duration: 300ms;

}

.footer-link:hover {
  transform: translateY(-0.25rem) scaleX(1.2) scaleY(1.2);
  transition-duration: 300ms;
}

.sharp-bg {
  /* height: 100vh; */
  background: linear-gradient(to bottom, #FBF7CF 200px, #F5F5F5 200px, #F5F5F5);
}

.sharp-bg-sm {
  /* height: 100vh; */
  background: linear-gradient(to bottom, #FBF7CF 50px, #F5F5F5 50px, #F5F5F5);
}

.sharp-bg-sm-white {
  /* height: 100vh; */
  background: linear-gradient(to bottom, #FBF7CF 50px, #fff 50px, #fff);
}

.about-bg {
  background: linear-gradient(to bottom, #fff 180px, #fff 180px, #fff);

}

.sharp-bg-sm-white {
  /* height: 100vh; */
  background: linear-gradient(to bottom, #FBF7CF 50px, #fff 50px, #fff);
}

.rotate {
  transform: rotate(315deg);
  transition: .3s all linear;
}

.rotate-chevron {
  transform: rotate(184deg);
  transition: .3s all linear;
}


.inactive {
  opacity: 0;
  height: 0;
  display: none;
  transition: .1s all linear;
}

.ex {
  background: url(./assets/svgs/Close.svg);
  width: 100px;
  height: 100px;
}

.input-field:focus {
  border: 1.66667px solid #EC892A !important;
  border-radius: 0%;
}

.cus-radio {
  border: 2px solid salmon;

}

.cus-radio:after {
  background-color: red;
}

.drop {
  margin: 0px !important;
}

.event-card-shadow {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
}


.date-cell {
  padding: 4px;
  width: 38px;
  height: 38px;
  margin: 0 2px;
}

.m0 {
  margin: 0px !important;
}

.calender-shadow {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.day {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
}


#num::after {
  content: 'hello';
  border-right: 1px red;
  height: 10px;
  display: block;
  width: 100px;
  background: #808080;

}

.history-list::marker {
  color: #EC892A;
  font-size: 1.5em;

}

.download-shadow {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
}

.report-card {
  /* background-image: url(./assets/images/report.png); */
}

.overlay {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 100%);
}

.overlay-modal {
  background: rgba(0, 0, 0, 0.6);
}

.donation-card-header {
  background: #F3F4F6;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 3px rgba(0, 0, 0, 0.1);
}

.don-border {
  border: 1px solid rgba(0, 0, 0, 0.45);
}


.don-color {
  color: rgba(0, 0, 0, 0.45);
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.partner-img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.partner-img:hover {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);

}



.menu-btn {
  position: relative;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 20px;
  cursor: pointer;
  transition: all .5s ease-in-out;
  transform: scale(0.5);
  z-index: 40 !important;
  /* border: 3px solid #fff; */
}

.menu-btn__burger {
  width: 50px;
  height: 6px;
  background: #EC892A;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
  transition: all .5s ease-in-out;
}

.menu-btn__burger::before,
.menu-btn__burger::after {
  content: '';
  position: absolute;
  width: 50px;
  height: 6px;
  background: #EC892A;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
  transition: all .5s ease-in-out;
}

.menu-btn__burger::before {
  transform: translateY(-16px);
}

.menu-btn__burger::after {
  transform: translateY(16px);
}

/* ANIMATION */
.menu-btn.open .menu-btn__burger {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}

.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}

.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}



.navbar-wrapper {
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    left: 100%;
  }

  100% {
    opacity: 1;
    left: 0;
  }
}



.menu-wrapper {
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  height: auto;
  z-index: 40;
  margin: auto;
  margin-top: 50px;

}

.menu-list {
  padding: 0px !important;
}

.inactive-menu-link {
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 1px solid #EC892A;
  text-align: center;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
  list-style: none;

}



.inactive-menu-link a:hover {
  color: #EC892A !important;
}



.active-menu-link {
  font-size: 1.5rem;
  font-family: 'SuisseIntl-SemiBold', sans-serif;
  font-weight: bold;
  border-bottom: 1px solid #EC892A;
  text-align: center;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
  list-style: none;

}




.active-menu-link a:hover {
  color: #EC892A;
}

.menu-main-wrapper {
  align-items: center;
  /* display: flex; */
  height: 100vh;
  justify-content: center;
  position: fixed;
  width: 78vw;
  z-index: 20;
  flex-direction: column;
  float: left;
}





.team-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  margin: 20px;
}

.item {
  grid-column: span 2;
}

/* Dealing with 2 orphan items */

.item:last-child:nth-child(3n - 1) {
  grid-column-end: -2;
}

.item:nth-last-child(2):nth-child(3n + 1) {
  grid-column-end: 4;
}

/* Dealing with single orphan */

.item:last-child:nth-child(3n - 2) {
  grid-column-end: 5;
}

.list-disc a {
  color: #EC892A;
}

#navbar {
  transition: top 0.3s;
}

.gallery-card:hover .image-desc {
  display: block;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.gallery-card:hover .gallery-main-image {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}





.owl-nav {
  justify-content: space-between;
  display: flex;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.owl-carousel {
  position: relative;
}

.custom-next {
  width: 64px;
  height: 64px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08);
  /* margin-right: 16px; */
  pointer-events: auto;
}


.custom-prev {
  width: 64px;
  height: 64px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08);
  margin-right: 16px;
  pointer-events: auto;
}

.custom-next:hover {
  background: white !important;
}

.custom-prev:hover {
  background: white !important;
}


.owl-next:hover {
  background: transparent !important;
}

.owl-prev:hover {
  background: transparent !important;
}


.owl-theme .owl-nav .disabled {
  visibility: hidden !important;
}

.owl-theme .owl-dots {
  display: none;
}



@media screen and (max-width: 600px) {
  .sucesss-card {
    min-width: -webkit-fill-available;
  }
}