@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SuisseIntl-Regular";
  src: url("./assets/fonts/SuisseIntl-Regular.otf");
}

@font-face {
  font-family: "SuisseIntl-Light";
  src: url("./assets/fonts/SuisseIntl-Light.otf");
}

@font-face {
  font-family: "SuisseIntl-Thin";
  src: url("./assets/fonts/SuisseIntl-Thin.otf");
}

@font-face {
  font-family: "SuisseIntl-SemiBold";
  src: url("./assets/fonts/SuisseIntl-SemiBold.otf");
}

@font-face {
  font-family: "SuisseIntl-Bold";
  src: url("./assets/fonts/SuisseIntl-Bold.otf");
}

@font-face {
  font-family: "SuisseIntl-Medium";
  src: url("./assets/fonts/SuisseIntl-Medium.otf");
}